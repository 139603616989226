.error-bar {
  word-break: break-all;
}
.error-bar--standalone {
  padding: 20px;
}
@media (min-width: 900px) {
  .error-bar--standalone {
    padding: 20px 40px 0 40px;
  }
}
.error-bar--smaller {
  padding: 0 20px 30px 20px;
}
@media (min-width: 900px) {
  .error-bar--smaller {
    padding: 0 40px 30px 40px;
  }
}
.error-bar__bar {
  background-color: #e52f00;
  border-radius: 3px;
  color: #fff;
  line-height: 1.3em;
  max-width: 780px;
  margin: 0 auto;
  padding: 1em;
  position: relative;
  z-index: 10;
  padding-right: 2em;
  text-align: left;
}
@media (min-width: 1700px) {
  .error-bar__bar {
    max-width: 100%;
  }
}
.error-bar__bar p:last-child {
  margin-bottom: 0;
}
.error-bar__message {
  margin-top: 1em;
}
.error-bar__close {
  position: absolute;
  right: 1em;
  top: 1em;
  /* increase the click area to about 32x32 */
}
.error-bar__close:hover {
  cursor: pointer;
}
.error-bar__close:after {
  content: "";
  position: absolute;
  top: -7px;
  bottom: -7px;
  left: -10px;
  right: -10px;
}
.error-bar a,
.error-bar a:visited,
.error-bar a:hover,
.error-bar a:active {
  color: inherit;
  text-decoration: underline;
}