@charset "UTF-8";
body {
  font-family: "Inter", Helvetica, arial, sans-serif;
}

a {
  color: #695be8;
  /* Color value of --zds-text-link */
}

.formatic .field-help {
  color: #777;
}

.formatic .required-text,
.formatic .not-required-text {
  font-size: 80%;
  color: #aaa;
}

.formatic .required-text:after {
  content: "(required)";
}

.formatic .not-required-text:after {
  content: "";
}

.formatic .validation-error-required .required-text:after {
  color: #ff4a00;
}

.formatic .dropdown-field {
  position: relative;
}

.formatic .field-value {
  border: 1px solid #ccc;
  padding: 10px;
}

.formatic .field-toggle {
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 42px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #ccc;
}

.formatic .field-toggle.field-open::before {
  content: "\25B2";
  vertical-align: middle;
}

.formatic .field-toggle.field-closed::before {
  content: "\25BC";
  vertical-align: middle;
}

.formatic .dropdown-field .field-choices-container {
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  background: #fff;
  bottom: 1px solid #ccc;
}

.formatic .dropdown-field .field-choices {
  list-style-type: none;
  margin-top: 0px;
  padding-left: 0px;
}

.formatic .choices-container {
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ccc;
}

.formatic .choices {
  list-style-type: none;
  margin: 0px;
  padding-left: 0px;
}

.formatic .choice-label {
  font-weight: 600;
  padding-right: 10px;
}

.formatic .choice-sample {
  color: #999;
}

.formatic .dropdown-field .field-choice {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.formatic .choice {
  padding: 0px;
}

.formatic .choice.hover {
  background-color: #ddd;
}

.formatic .reveal-enter {
  opacity: 0.01;
  transition: opacity 0.1s ease-in;
}

.formatic .reveal-enter.reveal-enter-active {
  opacity: 1;
}

.formatic .reveal-leave {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}

.formatic .reveal-leave.reveal-leave-active {
  opacity: 0.01;
}

.pretty-part {
  border-radius: 12px;
  background: #ff9266;
  cursor: pointer;
  padding-left: 7px;
  padding-right: 7px;
}

.pretty-part:hover {
  background: #ff4a00;
}

/* use div. to make this rule more specific than BS .form-control */
div.pretty-text-box {
  overflow: auto;
  height: 54px;
  resize: both;
  white-space: pre-wrap;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  line-height: 1.42857143;
  padding: 6px 12px;
}

.formatic .pretty-part-hover {
  background: #ff4a00;
}

.formatic .add-item,
.formatic .assoc-list-control-add {
  color: green;
  cursor: pointer;
}

.formatic .remove-item,
.formatic .assoc-list-control-remove {
  color: red;
  cursor: pointer;
  padding-right: 1em;
}

.formatic .maybe-removing {
  background: rgba(255, 0, 0, 0.25);
}

.formatic .move-item-back {
  cursor: pointer;
  padding-right: 1em;
}

.formatic .move-item-forward {
  cursor: pointer;
  padding-right: 1em;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

:before,
:after {
  box-sizing: border-box;
}

:before,
:after {
  box-sizing: border-box;
}

/*

FlowForm is a wrapper on Formatic. The goals are:

1. Assume there are either no/some common styles on root elements. Be sure to be explicit.
2. Try to reuse as much as possible (IE: heights are powering square buttons).
3. Don't depend on static width too much (unless you absolutely need to it in px).

There are lots of comments all over the place here - please read them before making changes!

*/
.flowform {
  position: relative;
  /* Overrides */
}
.flowform .fm-copy {
  display: block;
  position: relative;
  padding: 10px 12px;
  background-color: #f0f0f0;
  font-style: italic;
  margin-bottom: 30px;
  margin-top: -15px;
  font-size: 13px;
}
.flowform .clicklock-needs-hover {
  display: none;
}
.flowform.loading-needs {
  filter: blur(2px);
}
.flowform.loading-needs .clicklock-needs-hover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  background-color: white;
  opacity: 0.2;
  z-index: 3;
}
.flowform .formatic .fm-field {
  position: relative;
  min-height: 42px;
  padding: 0 0 20px;
}
.flowform .formatic .fm-field .copy-button {
  position: absolute;
  right: 0;
  margin: 5px;
  z-index: 2;
}
.flowform .formatic .fm-field .fm-custom-value {
  padding: 30px 0px 0px 0px;
}
.flowform .formatic .fm-field .fm-custom-list-child-value {
  padding: 10px 0px 0px 0px;
}
.flowform .formatic .fm-field .fm-field {
  padding-bottom: 10px;
}
.flowform .formatic .fm-field .fm-field .inline-checkbox {
  font-size: 13px;
  font-weight: 600px;
}
.flowform .formatic .fm-field .fm-field .inline-checkbox input {
  height: auto;
  width: auto;
  margin-right: 10px;
}
.flowform .formatic .fm-field:last-child {
  padding-bottom: 0;
}
.flowform .formatic .fm-field .fm-label {
  color: #354147;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 0.7em 0;
}
.flowform .formatic .fm-field .required-text:after {
  content: "(required)";
  color: #ff4a00;
  font-size: 11px;
  padding-right: 3px;
}
.flowform .formatic .fm-field .not-required-text:after {
  content: "(optional)";
  color: #969ea2;
  font-size: 11px;
  padding-right: 3px;
}
.flowform .formatic .fm-field .required-label {
  color: #969ea2;
  font-size: 11px;
  padding-right: 3px;
}
.flowform .formatic .fm-field .required-label.required {
  color: #ff4a00;
}
.flowform .formatic .fm-field .label-logo-wrapper .label-logo {
  display: inline-block;
  margin-top: -2px;
  margin-right: 0.5em;
}
.flowform .formatic .fm-field .label-logo-wrapper .fm-label {
  display: inline-block;
}
.flowform .formatic .fm-field .fm-help {
  color: inherit;
  font-size: 13px;
}
.flowform .formatic .fm-field .fm-help p {
  margin-bottom: 10px;
}
.flowform .formatic .fm-field .fm-help.fm-help-overridden p {
  margin-bottom: 0.5em;
  color: inherit;
  background: #f1f4f5;
  display: inline-block;
  padding: 0.1em 0.5em;
  border-radius: 3px;
}
.flowform .formatic .fm-field .fm-help .fm-help-text-bulleted-list {
  list-style: disc;
  margin-left: 20px;
}
.flowform .formatic .fm-field:hover .fm-help {
  display: block;
}
.flowform .formatic .fm-field input[type=text],
.flowform .formatic .fm-field textarea {
  -webkit-appearance: none;
}
.flowform .formatic .fm-field .fm-assoc-list-item input, .flowform .formatic .fm-field .choices-search input, .flowform .formatic .fm-field .fm-singlelinestring, .flowform .formatic .fm-field .fm-password, .flowform .formatic .fm-field .fm-email,
.flowform .formatic .fm-field div.pretty-text-box, .flowform .formatic .fm-field .fm-string {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  background: #fff;
  overflow: auto;
  white-space: pre-wrap;
  height: auto;
  width: 100%;
  line-height: 1.7;
  min-height: 45px;
  padding: 10px;
  margin: 0;
  border: 1px solid #dadfe2;
  border-radius: 0;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}
.flowform .formatic .fm-field .fm-assoc-list-item input:focus, .flowform .formatic .fm-field .choices-search input:focus, .flowform .formatic .fm-field .fm-singlelinestring:focus, .flowform .formatic .fm-field .fm-password:focus, .flowform .formatic .fm-field .fm-email:focus,
.flowform .formatic .fm-field div.pretty-text-box:focus, .flowform .formatic .fm-field .fm-string:focus {
  border-color: #dadfe2;
}
.flowform .formatic .fm-field .fm-assoc-list-item input:hover, .flowform .formatic .fm-field .choices-search input:hover, .flowform .formatic .fm-field .fm-singlelinestring:hover, .flowform .formatic .fm-field .fm-password:hover, .flowform .formatic .fm-field .fm-email:hover,
.flowform .formatic .fm-field div.pretty-text-box:hover, .flowform .formatic .fm-field .fm-string:hover {
  background-color: #fff;
  border-color: #bfc3c6;
}
.flowform .formatic .fm-field .fm-assoc-list-item input.error, .flowform .formatic .fm-field .choices-search input.error, .flowform .formatic .fm-field .fm-singlelinestring.error, .flowform .formatic .fm-field .fm-password.error, .flowform .formatic .fm-field .fm-email.error,
.flowform .formatic .fm-field div.pretty-text-box.error, .flowform .formatic .fm-field .fm-string.error {
  background: #ffeeeb;
  border-color: #e52f00;
  border-width: 2px;
}
.flowform .formatic .fm-field .fm-assoc-list-item input.error .CodeMirror-code, .flowform .formatic .fm-field .choices-search input.error .CodeMirror-code, .flowform .formatic .fm-field .fm-singlelinestring.error .CodeMirror-code, .flowform .formatic .fm-field .fm-password.error .CodeMirror-code, .flowform .formatic .fm-field .fm-email.error .CodeMirror-code,
.flowform .formatic .fm-field div.pretty-text-box.error .CodeMirror-code, .flowform .formatic .fm-field .fm-string.error .CodeMirror-code {
  background: #ffeeeb;
}
.flowform .formatic .fm-field .fm-assoc-list-item input.warning, .flowform .formatic .fm-field .choices-search input.warning, .flowform .formatic .fm-field .fm-singlelinestring.warning, .flowform .formatic .fm-field .fm-password.warning, .flowform .formatic .fm-field .fm-email.warning,
.flowform .formatic .fm-field div.pretty-text-box.warning, .flowform .formatic .fm-field .fm-string.warning {
  background: #fff3d7;
  border-color: #ffc43e;
  border-width: 2px;
}
.flowform .formatic .fm-field .fm-assoc-list-item input.warning .CodeMirror-code, .flowform .formatic .fm-field .choices-search input.warning .CodeMirror-code, .flowform .formatic .fm-field .fm-singlelinestring.warning .CodeMirror-code, .flowform .formatic .fm-field .fm-password.warning .CodeMirror-code, .flowform .formatic .fm-field .fm-email.warning .CodeMirror-code,
.flowform .formatic .fm-field div.pretty-text-box.warning .CodeMirror-code, .flowform .formatic .fm-field .fm-string.warning .CodeMirror-code {
  background: #fff3d7;
}
.flowform .formatic .fm-field .fm-assoc-list-item input, .flowform .formatic .fm-field .choices-search input, .flowform .formatic .fm-field .fm-singlelinestring, .flowform .formatic .fm-field .fm-password, .flowform .formatic .fm-field .fm-email {
  height: 45px;
  border-radius: 3px;
}
.flowform .formatic .fm-field.fm-field-type-text div.pretty-text-box,
.flowform .formatic .fm-field.fm-field-type-text input {
  min-height: 90px;
}
.flowform .formatic .fm-field.fm-field-type-text div.pretty-text-box .CodeMirror-code {
  min-height: 68px;
}
.flowform .formatic .fm-field.fm-field-type-code div.pretty-text-box,
.flowform .formatic .fm-field.fm-field-type-code input {
  font-family: monospace;
}
.flowform .formatic .fm-field div.pretty-text-box .CodeMirror {
  height: auto;
  width: 100%;
}
.flowform .formatic .fm-field div.pretty-text-box.has-focus {
  border-color: #bfc3c6;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part {
  background-color: #e8ebed;
  border-radius: 12px;
  border: 2px solid #e8ebed;
  color: #354147;
  display: inline-flex;
  flex-direction: row;
  line-height: normal;
  margin-right: 0.0625em;
  transition: all 0.1s ease;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part:not(.readonly-control):hover {
  background: #969ea2;
  border: solid 2px #969ea2;
  color: #fff;
  cursor: pointer;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part--preview {
  background-color: #62d493;
  border: 2px solid #62d493;
  margin: 0 1px;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part--readonly-preview {
  margin: 0 1px;
  background-color: #8adfaf;
  border: 2px solid #8adfaf;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-error {
  color: #e52f00;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-container {
  align-items: center;
  background: #fff;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-container--preview {
  background: #eefaf3;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-icon .img16 {
  position: relative;
  top: -1px;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-step-number {
  color: #5f6c72;
  font-size: 0.85em;
  padding-left: 5px;
  position: relative;
  top: -1px;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-step-number::before {
  content: "Step\A0";
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-step-number--preview {
  color: #3ac977;
  font-weight: 600;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-label {
  align-items: center;
  display: flex;
  font-size: 0.85em;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  top: -1px;
  word-break: break-all;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-label--preview {
  color: #fff;
}
.flowform .formatic .fm-field div.pretty-text-box .pretty-part-label-icon {
  margin-right: 5px;
}
.flowform .formatic .fm-field div.pretty-text-wrapper {
  position: relative;
}
.flowform .formatic .fm-field div.pretty-text-wrapper div.pretty-text-box {
  padding-right: 55px;
}
.flowform .formatic .fm-field div.pretty-text-wrapper div.insert-button {
  color: transparent;
  display: block;
  position: absolute;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: 0;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border-radius: 0 3px 3px 0;
  background-image: url("/images/frontend/insert-fields-2.png");
  background-position: center;
  background-size: 55%;
}
.flowform .formatic .fm-field div.pretty-text-wrapper div.insert-button:not(.readonly-control) {
  cursor: pointer;
}
.flowform .formatic .fm-field div.pretty-text-wrapper div.insert-button:not(.readonly-control):hover, .flowform .formatic .fm-field div.pretty-text-wrapper div.insert-button:not(.readonly-control):focus {
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
.flowform .formatic .fm-field div.pretty-text-wrapper .error ~ div.insert-button {
  border-color: #e52f00;
}
.flowform .formatic .fm-field div.pretty-text-wrapper .warning ~ div.insert-button {
  border-color: #ffc43e;
}
.flowform .formatic .fm-field.fm-prettyselect.error input,
.flowform .formatic .fm-field.fm-prettyselect.error .select-arrow {
  color: #000;
  border-color: #e52f00;
  border-width: 1px;
}
.flowform .formatic .fm-field .fm-pretty-select-value {
  position: relative;
}
.flowform .formatic .fm-field .fm-pretty-select-value .select-arrow {
  display: block;
  position: absolute;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: 0;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border-radius: 0 3px 3px 0;
  background-image: url("/images/arrow-down.png");
  background-size: 35%;
}
.flowform .formatic .fm-field .fm-pretty-select-value .select-arrow:not(.readonly-control) {
  cursor: pointer;
}
.flowform .formatic .fm-field .fm-pretty-select-value .select-arrow:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-pretty-select-value .select-arrow:not(.readonly-control):focus {
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
.flowform .formatic .fm-field .fm-pretty-select-value .select-arrow.error {
  border-color: #e52f00;
  border-width: 1px;
}
.flowform .formatic .fm-field .fm-assoc-list {
  position: relative;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item {
  padding-bottom: 10px;
  display: flex;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item:after {
  content: "";
  display: table;
  clear: both;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item div.fm-assoc-list-item-key {
  margin-right: 1%;
  flex-shrink: 0;
  flex-basis: 33%;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item div.fm-assoc-list-item-value {
  flex: 1 1;
  width: 33%;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item .fm-assoc-list-item-control .fm-remove-item {
  display: block;
  position: absolute;
  color: transparent;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: -110px;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border-radius: 3px;
  position: relative;
  right: 0;
  margin-left: 10px;
  background-image: url("/images/icons/minus.png");
  background-size: 25%;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item .fm-assoc-list-item-control .fm-remove-item:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-item .fm-assoc-list-item-control .fm-remove-item:not(.readonly-control):focus {
  cursor: pointer;
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-control {
  position: relative;
  height: 45px;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-control .fm-add-item {
  display: block;
  position: absolute;
  color: transparent;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: -110px;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  border-radius: 3px;
  right: 0;
  background-image: url("/images/icons/plus.png");
  background-size: 25%;
}
.flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-control .fm-add-item:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-assoc-list .fm-assoc-list-control .fm-add-item:not(.readonly-control):focus {
  cursor: pointer;
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
.flowform .formatic .fm-field .fm-array {
  position: relative;
  margin-right: 110px;
}
@media screen and (max-width: 480px) {
  .flowform .formatic .fm-field .fm-array {
    margin-right: 45px;
  }
}
.flowform .formatic .fm-field .fm-array .fm-array-item {
  position: relative;
  margin: 8px 0;
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-remove-item {
  border-radius: 3px;
  display: block;
  position: absolute;
  color: transparent;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: -110px;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background-image: url("/images/icons/minus.png");
  background-size: 25%;
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-remove-item:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-remove-item:not(.readonly-control):focus {
  cursor: pointer;
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
@media screen and (max-width: 480px) {
  .flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-remove-item {
    right: -55px;
  }
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-back {
  display: block;
  position: absolute;
  color: transparent;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: -110px;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  height: 22.5px;
  right: -55px;
  border: 2px solid #dadfe2;
  background-image: url("/images/icons/arrow-up.png");
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-back:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-back:not(.readonly-control):focus {
  cursor: pointer;
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
@media screen and (max-width: 480px) {
  .flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-back {
    display: none;
  }
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-back.is-last-item {
  top: 12px;
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-forward {
  display: block;
  position: absolute;
  color: transparent;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: -110px;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  top: 22.5px;
  height: 22.5px;
  right: -55px;
  background-image: url("/images/icons/arrow-down.png");
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-forward:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-forward:not(.readonly-control):focus {
  cursor: pointer;
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
@media screen and (max-width: 480px) {
  .flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-forward {
    display: none;
  }
}
.flowform .formatic .fm-field .fm-array .fm-array-item .fm-array-item-control .fm-move-item-forward.is-first-item {
  top: 12px;
}
.flowform .formatic .fm-field .fm-array .fm-array-control {
  position: relative;
  height: 45px;
}
.flowform .formatic .fm-field .fm-array .fm-array-control .fm-add-item {
  border-radius: 3px;
  display: block;
  position: absolute;
  color: transparent;
  background-color: #fff;
  width: 45px;
  height: 45px;
  top: 0;
  right: -110px;
  border: 1px solid #dadfe2;
  border-radius: 0;
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  background-image: url("/images/icons/plus.png");
  background-size: 25%;
}
.flowform .formatic .fm-field .fm-array .fm-array-control .fm-add-item:not(.readonly-control):hover, .flowform .formatic .fm-field .fm-array .fm-array-control .fm-add-item:not(.readonly-control):focus {
  cursor: pointer;
  border-color: #bfc3c6;
  background-color: #f9f9f9;
}
@media screen and (max-width: 480px) {
  .flowform .formatic .fm-field .fm-array .fm-array-control .fm-add-item {
    right: -55px;
  }
}
.flowform .formatic .fm-field .loading-choices {
  color: #999;
}
.flowform .formatic .fm-field .loading-choices input {
  font-style: italic;
}
.flowform .formatic .fm-field .choices-container {
  background: #fff;
  max-height: 300px;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
  margin-bottom: 50px;
  border: 0;
  /* Custom Scrollbar for Dropdowns */
}
.flowform .formatic .fm-field .choices-container::-webkit-scrollbar {
  background-color: #fff;
  height: 8px;
  width: 7px;
}
.flowform .formatic .fm-field .choices-container::-webkit-scrollbar-thumb {
  background-color: #ccd3d7;
  border-radius: 5px;
  margin-top: 10px;
}
.flowform .formatic .fm-field .choices-container::-webkit-scrollbar-track {
  background-color: #f7f8f8;
  border-radius: 0;
  width: 10px;
  margin: 2px;
}
.flowform .formatic .fm-field .choices-container::-webkit-scrollbar-corner {
  background-color: #fff;
}
.flowform .formatic .fm-field .choices-container .choices-search__search-icon {
  fill: #969ea2;
  position: absolute;
  top: 12px;
  left: 12px;
}
.flowform .formatic .fm-field .choices-container .choices-search input {
  background: linear-gradient(to bottom, #fff, #f7f9fa);
  color: #5f6c72;
  padding: 9px 8px 7px 2.8em;
  height: 40px;
  min-height: 40px;
  border: 0;
  border-bottom: 1px solid #bfc3c6;
  border-radius: 0;
}
.flowform .formatic .fm-field .choices-container ul.choices {
  margin: 0;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice {
  border-top: 1px solid #dadfe2;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice:first-child {
  border-top: none;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice .action-choice {
  display: flex;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice .action-choice .choice-other-action {
  order: -1;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a {
  cursor: pointer;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a {
  white-space: nowrap;
  overflow: hidden;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #999;
  display: block;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-section-header {
  display: flex;
  background: #fff;
  padding: 12px 8px 12px 0;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-section-header .choice-name {
  color: #354147;
  font-weight: 600;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-fancy-header {
  float: left;
  margin: 4px 8px;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .empty-choices-section {
  color: #e52f00;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .empty-choices-section .choice-name {
  color: #e52f00;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-section-arrow {
  margin-left: auto;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-section-arrow .svg-icon {
  vertical-align: middle;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-label {
  color: #5f6c72;
  display: inline-block;
  font-weight: 600;
  margin-right: 1%;
  padding: 10px;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-label:only-child {
  max-width: none;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-label.complete-step {
  white-space: nowrap;
  overflow: hidden;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-sample {
  color: #aeb7bc;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-name {
  color: #354147;
  margin-left: 1%;
  vertical-align: middle;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-step-number {
  margin-right: 2%;
  background-color: #f1f4f5;
  color: #5f6c72;
  display: inline-block;
  text-align: center;
  height: 22px;
  width: 22px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-step-number.disabled {
  opacity: 0.4;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .other-options .choice-name {
  margin-left: 0;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a:hover {
  text-decoration: none;
  background: #eef0f2;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-action {
  color: #666;
  float: right;
  margin-top: 12px;
  margin-right: 8px;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a .choice-other-action {
  /* color: #666; */
  float: left;
  margin: 11px 10px 0;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.enter-custom-value,
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.clear-current-choice,
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.empty-choices,
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.load-more-choices,
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.load-external-link {
  padding: 10px 10px 10px 0;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.enter-custom-value .title {
  width: 100%;
  display: block;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice a span.enter-custom-value .description {
  font-weight: normal;
  white-space: normal;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice .choice-row {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 12px 8px;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice .choice-row__nest-arrow {
  color: #969ea2;
  margin-right: 10px;
  margin-left: 10px;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice .choice-row__emoji-wrapper {
  margin-right: 2%;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice .choice-row .choice-name {
  white-space: nowrap;
  overflow: hidden;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
  color: #354147;
  font-weight: 600;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice:first-child {
  border-top: 0;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice.hover a {
  background-color: #eef0f2;
}
.flowform .formatic .fm-field .choices-container ul.choices li.choice.hover a .choice-row {
  background-color: #eef0f2;
}
.flowform .formatic .fm-field div.pretty-text-wrapper div.pretty-text-box {
  border-radius: 3px;
}
.flowform .formatic .fm-field div.pretty-text-wrapper .pretty-text-click-wrapper {
  width: 100%;
}
.flowform .formatic .fm-field div.pretty-text-wrapper.choices-open div.insert-button {
  border: 1px solid #bfc3c6;
  border-radius: 0 3px 0 0;
}
.flowform .formatic .fm-field div.pretty-text-wrapper.choices-open div.pretty-text-box {
  border: 1px solid #bfc3c6;
  border-radius: 3px 3px 0 0;
}
.flowform .formatic .fm-field div.fm-pretty-select-value.choices-open .select-arrow,
.flowform .formatic .fm-field div.fm-pretty-select-value:focus .select-arrow {
  border: 1px solid #bfc3c6;
  border-radius: 0 3px 0 0;
}
.flowform .formatic .fm-field div.fm-pretty-select-value.choices-open {
  z-index: 900;
}
.flowform .formatic .fm-field div.fm-pretty-select-value.choices-open .fm-pretty-select-input {
  border-top: 1px solid #bfc3c6;
  border-right: 1px solid #bfc3c6;
  border-left: 1px solid #bfc3c6;
}
.flowform .formatic .fm-field div.fm-pretty-select-value .pretty-text-wrapper.choices-open + .select-arrow {
  border: 1px solid #bfc3c6;
}
.flowform .formatic .fm-field .choices-container {
  border: 1px solid #bfc3c6;
  margin-top: -2px;
  border-radius: 0 0 3px 3px;
}
.flowform .formatic .fm-field .choices-container li.choice a {
  background: #f7f8f8;
}
.flowform .formatic .fm-field-type-fields .fm-label {
  color: #354147;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 0.7em 0;
}
.flowform .child-fields-group {
  border: 2px solid #eee;
  padding: 40px;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  .flowform .child-fields-group {
    padding: 20px;
  }
}
.flowform .child-fields-group legend {
  padding: 0 20px;
  margin-left: -20px;
  font-size: 1.3em;
}
.flowform .child-fields-group .read-only-field .label-logo-wrapper span.label-logo,
.flowform .child-fields-group .read-only-field span.required-text,
.flowform .child-fields-group .read-only-field span.not-required-text,
.flowform .child-fields-group .read-only-field .fm-help,
.flowform .child-fields-group .read-only-field div.pretty-text-wrapper div.insert-button {
  display: none;
}
.flowform .child-fields-group .read-only-field:hover .fm-help {
  display: none;
}
.flowform .child-fields-group .read-only-field div.pretty-text-box {
  border: none;
  padding: 0;
}
.flowform .child-fields-group .read-only-field .fm-assoc-list {
  margin-right: 0;
}
.flowform .child-fields-group .read-only-field .fm-assoc-list .fm-assoc-list-item .fm-assoc-list-item-control {
  display: none;
}
.flowform .child-fields-group .read-only-field .fm-assoc-list .fm-assoc-list-control {
  display: none;
}
.flowform .child-fields-group .read-only-field .fm-array {
  margin-right: 0;
}
.flowform .child-fields-group .read-only-field .fm-array .fm-array-item .fm-array-item-control {
  display: none;
}
.flowform .child-fields-group .read-only-field .fm-array .fm-array-control {
  display: none;
}
.flowform .child-fields-group .read-only-field .select-arrow {
  display: none;
}
.flowform .child-fields-group .read-only-field .pretty-text-box,
.flowform .child-fields-group .read-only-field input,
.flowform .child-fields-group .read-only-field .CodeMirror {
  background-color: #fff;
  color: #5f6c72;
  font-weight: normal;
  border: none;
  padding: 0;
  font-family: "Consolas", "Liberation Mono", Courier, monospace;
}
.flowform .fm-field label.inline-checkbox {
  font-size: 13px;
  font-weight: 600;
}
.flowform .fm-field label.inline-checkbox input {
  height: auto;
  width: auto;
  margin-right: 0.7em;
  vertical-align: middle;
}
.flowform__pretty-button {
  height: 45px;
  padding: 15px;
}

.flowform.rule-cell .formatic .fm-field .choices-container,
.flowform .formatic .rule-cell .choices-container {
  max-width: 600px;
  min-width: 300px;
  width: auto;
}
@media (max-width: 600px) {
  .flowform.rule-cell .formatic .fm-field .choices-container,
.flowform .formatic .rule-cell .choices-container {
    width: 100%;
  }
}

.flowform .column .choices-container {
  min-width: 300px;
}

.flowform.readonly-rule input {
  border: 0;
}

.flowform .placeholder .internal-text-wrapper {
  color: #969ea2;
}

.fm-field-type-filter div.pretty-text-wrapper div.pretty-text-box {
  max-height: 150px;
}

/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
}

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0;
  /* Vertical padding around content */
}

.CodeMirror pre {
  padding: 0 4px;
  /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white;
  /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  box-sizing: content-box;
}

.CodeMirror-guttermarker {
  color: black;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */
.CodeMirror div.CodeMirror-cursor {
  border-left: 1px solid black;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.CodeMirror.cm-fat-cursor div.CodeMirror-cursor {
  width: auto;
  border: 0;
  background: #7e7;
}

.CodeMirror.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  animation: blink 1.06s steps(1) infinite;
}
@keyframes blink {
  0% {
    background: #7e7;
  }
  50% {
    background: none;
  }
  100% {
    background: #7e7;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
}

/* DEFAULT THEME */
.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3 {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header,
.cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0f0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #f22;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important;
  /* Things will break if this is overridden */
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none;
  /* Prevent dragging from highlighting the element */
  position: relative;
  box-sizing: content-box;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
  box-sizing: content-box;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actuall scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  box-sizing: content-box;
  display: inline-block;
  margin-bottom: -30px;
  /* Hack to make IE7 behave */
  *zoom: 1;
  *display: inline;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  height: 100%;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper {
  -webkit-user-select: none;
  user-select: none;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
  /* prevents collapsing before first draw */
}

.CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
}

.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  overflow: auto;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-measure pre {
  position: static;
}

.CodeMirror div.CodeMirror-cursor {
  position: absolute;
  border-right: none;
  width: 0;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror ::selection {
  background: #d7d4f0;
}

.CodeMirror ::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background: #ffa;
  background: rgba(255, 255, 0, 0.4);
}

/* IE7 hack to prevent it from returning funny offsetTops on the spans */
.CodeMirror span {
  *vertical-align: text-bottom;
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  height: 300px;
}

.CodeMirror-lines {
  padding: 0 0;
  /* Vertical padding around content */
}

.CodeMirror pre {
  padding: 0 0;
  /* Horizontal padding of content */
}

.CodeMirror:not(.CodeMirror-focused) .cm-trailingspace,
.cm-trailingspace-read-only {
  border-bottom: #ff4a00 solid 1px;
  background-position: bottom left;
  background-repeat: repeat-x;
}

.img128,
.img64,
.img32,
.img16 {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  background-position: center center;
  background-repeat: no-repeat;
}

.img128 {
  width: 128px;
  height: 128px;
  background-size: 64px 64px;
}

.img64 {
  width: 64px;
  height: 64px;
  background-size: 64px 64px;
}

.img32 {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
}

.img16 {
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}

.img16.placeholder,
.PlaceholderAPI16x16,
[class$=EditorPreview-styles--preview] .PlaceholderAPI16x16 {
  background-image: url("/images/placeholder/16x16-new.grey.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.img32.placeholder,
.PlaceholderAPI32x32,
[class$=EditorPreview-styles--preview] .PlaceholderAPI32x32 {
  background-image: url("/images/placeholder/32x32-new.grey.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.img64.placeholder,
.PlaceholderAPI64x64,
[class$=EditorPreview-styles--preview] .PlaceholderAPI64x64 {
  background-image: url("/images/placeholder/64x64-new.grey.png");
  background-position: center center;
  background-repeat: no-repeat;
}

.img128.placeholder,
.PlaceholderAPI128x128,
[class$=EditorPreview-styles--preview] .PlaceholderAPI128x128 {
  background-image: url("/images/placeholder/128x128-new.grey.png");
  background-position: center center;
  background-repeat: no-repeat;
}