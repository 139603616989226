.react-portal-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(53, 65, 71, 0.9);
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-portal-modal .react-portal-modal__inner {
  position: relative;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  padding: 40px;
  max-width: 600px;
  width: 100%;
  max-height: 100vh;
}
.react-portal-modal .react-portal-modal__inner--form {
  width: auto;
  max-width: 90vw;
  padding: 0;
}
@media (max-width: 700px) {
  .react-portal-modal .react-portal-modal__inner--form {
    width: 100%;
  }
}
.react-portal-modal .react-portal-modal__inner--condensed {
  padding: 15px;
}
.react-portal-modal .react-portal-modal__inner--condensed__actions {
  margin-top: 15px;
}
.react-portal-modal .react-portal-modal__inner--narrow {
  max-width: 500px;
}
.react-portal-modal .react-portal-modal__inner--no-overflow {
  overflow: initial;
}
.react-portal-modal .react-portal-modal__inner--overflow-hidden {
  overflow: hidden;
}
.react-portal-modal__content h1 {
  font-size: 1.8em;
  margin: 0 0 2.5rem 0;
  padding: 0;
}
.react-portal-modal__content h2 {
  margin-bottom: 1.5rem;
  padding: 0;
}
.react-portal-modal__content h4 {
  color: #969ea2;
  font-size: 0.9em;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.react-portal-modal__content p,
.react-portal-modal__content ul {
  color: #5f6c72;
  font-size: 1.1em;
}
.react-portal-modal__content em {
  font-weight: 600;
  font-style: normal;
}
.react-portal-modal__content--smaller h1 {
  font-size: 1em;
}
.react-portal-modal .modal-button {
  margin: 2.5em auto 0 auto;
}
.react-portal-modal .modal-button.center {
  text-align: center;
}
.react-portal-modal__actions {
  margin-top: 3.5rem;
}
.react-portal-modal__actions-button {
  min-width: 12rem;
}
.react-portal-modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #969ea2;
}
.react-portal-modal__close:hover {
  color: #5f6c72;
}