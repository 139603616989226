@charset "UTF-8";
.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  fill: currentColor;
}
.svg-icon * {
  fill: inherit;
}
.svg-icon--clickable {
  cursor: pointer;
}
.svg-icon--is-error {
  fill: #e52f00;
}
.svg-icon--is-success {
  fill: #13d0ab;
}
.svg-icon--is-warning {
  fill: #ffc43e;
}
.svg-icon--primary {
  fill: #499df3 !important;
}
.svg-icon--branded {
  fill: #ff4a00;
}
.svg-icon--subtle {
  fill: #969ea2 !important;
}
.svg-icon--subtlest {
  fill: #dadfe2 !important;
}
.svg-icon--does-not-exist {
  fill: none;
  stroke: #f00;
  stroke-width: 1px;
}
.svg-icon--spin {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-359deg);
  }
}
.svg-icon > svg {
  display: block;
  width: inherit;
  height: inherit;
}

.icon,
.fa {
  display: inline-block;
  border: 1px solid #f00;
  width: 1em;
  font-size: 14px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}
.icon::after,
.fa::after {
  content: "\D7";
  color: #f00;
  font-size: 1em;
}