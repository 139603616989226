.info-block {
  border: 1px solid #dadfe2;
  border-radius: 3px;
  padding: 13px;
  text-align: left;
}
.info-block--bare {
  border: none;
  padding: 0;
}
.info-block--borderless {
  border: none;
}
.info-block--unpadded {
  padding: 0;
}
.info-block--centered {
  text-align: center;
}
.info-block--page {
  background: #fff;
  border: none;
  padding: 13px;
}
@media screen and (min-width: 600px) {
  .info-block--page {
    border: 1px solid #dadfe2;
    padding: 45px;
    margin: 60px auto;
    max-width: 600px;
  }
}
@media screen and (min-width: 700px) {
  .info-block--page.info-block--page-large {
    max-width: 700px;
  }
}
.info-block--note {
  border-left: 7px solid #969ea2;
  background-color: #f1f4f5;
}
.info-block--note-warning {
  border-left: 7px solid #ffc43e;
  background-color: #fff6e1;
}
.info-block__inner {
  padding: 13px;
}
.info-block__icon-wrap {
  display: flex;
  justify-content: center;
  margin: 0 auto 25px;
}
.info-block__icon {
  width: 40px;
  height: 40px;
}
.info-block__image {
  display: block;
  margin: 0 auto 25px;
}
.info-block__pre-title {
  color: #969ea2;
  text-transform: uppercase;
  margin: 0 0 20px;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}
.info-block__title {
  margin: 0 0 25px;
  padding: 0;
  text-align: center;
  font-size: 22px;
}
.info-block__title--smaller {
  font-size: 20px;
}
.info-block__description {
  color: #5f6c72;
  line-height: 1.5;
  margin: 0 auto 1.5rem;
  font-size: 16px;
  max-width: 440px;
}
.info-block__description:not(:last-child) {
  margin-bottom: 1.5rem;
}
.info-block__description--centered {
  text-align: center;
}
.info-block__description p {
  line-height: inherit;
  margin: 0 0 1em;
}
.info-block__description p:last-child {
  margin-bottom: 0;
}
.info-block__content {
  margin: 0 0 1.5rem;
}
.info-block__content--top-rule {
  border-top: 1px solid #dadfe2;
  padding-top: 1.5rem;
}
.info-block__footer {
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
}
.info-block__footer--justify-end {
  justify-content: flex-end;
}
.info-block__add-on-footer {
  color: #5f6c72;
  line-height: normal;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  border-top: 1px solid #dadfe2;
  background: #f1f4f5;
  padding: 13px;
}