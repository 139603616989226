.error-summary {
  display: flex;
}
.error-summary__summary {
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
}
.error-summary .error-summary__icon {
  width: 16px;
  height: 16px;
  fill: #fff;
}
.error-summary__error {
  margin-left: 10px;
}
.error-summary__help {
  margin-top: 5px;
  font-weight: 600;
  opacity: 0.75;
  color: #fff;
  font-size: 1.2rem;
}
.error-summary__list {
  margin: 0;
}