@keyframes load-progressively {
  0% {
    width: 0%;
  }
  100% {
    width: 99%;
  }
}
.loading-next-route-indicator {
  animation-name: load-progressively;
  animation-duration: 4s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.04, 0.96, 0.05, 0.99);
  transition: width 1s ease-in;
  -webkit-user-select: none;
          user-select: none;
  background-color: #ff4a00;
  position: fixed;
  top: 0;
  width: 0%;
  height: 2px;
  z-index: 10100;
}