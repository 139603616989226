:root {
  --zds-radius-none: 0;
  --zds-radius-small: 0.1875rem;
  --zds-radius-medium: 0.3125rem;
  /* Default value */
  --zds-radius-large: 0.5rem;
  --zds-radius-pill: 3.125rem;
  --zds-gray-warm-1: #f9f7f3;
  /* UI Background option */
  --zds-gray-warm-2: #f5f3eb;
  /* UI Background option */
  --zds-gray-warm-3: #ece9df;
  /* UI Background option */
  --zds-gray-warm-4: #d7d3c9;
  /* UI Stroke option */
  --zds-gray-warm-5: #b5b2aa;
  /* UI Stroke option */
  --zds-gray-warm-6: #88827e;
  /* UI Stroke option */
  --zds-gray-warm-7: #6f6765;
  /* UI Text option */
  --zds-gray-warm-8: #574e4c;
  /* UI Text option */
  --zds-gray-warm-9: #413735;
  /* UI Text option */
  --zds-gray-warm-10: #201515;
  /* UI Text option */
  --zds-brand-orange: #ff4f00;
  --zds-brand-earth: #201515;
  --zds-brand-lavender: #c1b7ff;
  --zds-brand-night: #2b2358;
  --zds-brand-almost-white: #fffdf9;
  --zds-brand-peach: #ffbf6e;
  --zds-brand-cream: #fff3e6;
  --zds-brand-bolt: #f6ffdb;
  --zds-brand-neon: #ddff76;
  --zds-brand-sky: #cde4e1;
  --zds-brand-moss: #1f3121;
  /* Unused in product */
  --zds-color-purple-1: #f7f6fd;
  --zds-color-purple-2: #d5d7fc;
  --zds-color-purple-3: #847dfe;
  --zds-color-purple-4: #695be8;
  --zds-color-purple-5: #503ebd;
  --zds-color-yellow-1: #fff8d9;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-yellow-2: #f4db6f;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-yellow-3: #a88d10;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-yellow-4: #86700b;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-yellow-5: #645304;
  /* Hover (7:1 contrast against background default) */
  --zds-color-red-1: #fff2ef;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-red-2: #fdc9c6;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-red-3: #f65258;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-red-4: #d12d37;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-red-5: #a81227;
  /* Hover (7:1 contrast against background default) */
  --zds-color-blue-1: #f0f8fc;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-blue-2: #aee1fd;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-blue-3: #3298c7;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-blue-4: #1979a2;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-blue-5: #035a7b;
  /* Hover (7:1 contrast against background default) */
  --zds-color-orange-1: #fdf7f2;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-orange-2: #fdd0ae;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-orange-3: #d57601;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-orange-4: #aa5f0e;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-orange-5: #7b4714;
  /* Hover (7:1 contrast against background default) */
  --zds-color-teal-1: #e4fbf7;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-teal-2: #a1e5dd;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-teal-3: #1a9f94;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-teal-4: #0f7e75;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-teal-5: #125f58;
  /* Hover (7:1 contrast against background default) */
  --zds-color-green-1: #e7ffe7;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-green-2: #a7eaaa;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-green-3: #2ea343;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-green-4: #0a842a;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-green-5: #156123;
  /* Hover (7:1 contrast against background default) */
  --zds-color-pink-1: #fdf6fb;
  /* Background (min 4.5:1 contrast against 4 Text) */
  --zds-color-pink-2: #feccf2;
  /* Background (min 4.5:1 contrast against text default) */
  --zds-color-pink-3: #d367bd;
  /* Stroke (3:1 contrast against background default) */
  --zds-color-pink-4: #aa509a;
  /* Text (4.5:1 contrast against background default) */
  --zds-color-pink-5: #7c3e70;
  /* Hover (7:1 contrast against background default) */
  --zds-prime-white: #fffdf9;
  --zds-prime-black: #201515;
  --zds-prime-purple: #847dfe;
  --zds-prime-blue: #3298c7;
  --zds-prime-teal: #1a9f94;
  --zds-prime-green: #2ea343;
  --zds-prime-yellow: #a88d10;
  --zds-prime-orange: #d57601;
  --zds-prime-red: #f65258;
  --zds-prime-pink: #d367bd;
  --zds-size-2: 0.125rem;
  --zds-size-4: 0.25rem;
  --zds-size-8: 0.5rem;
  --zds-size-16: 1rem;
  --zds-size-20: 1.25rem;
  --zds-size-24: 1.5rem;
  --zds-size-32: 2rem;
  --zds-size-36: 2.25rem;
  --zds-size-40: 2.5rem;
  --zds-size-48: 3rem;
  --zds-space-0: 0;
  --zds-space-2: 0.125rem;
  --zds-space-4: 0.25rem;
  --zds-space-6: 0.375rem;
  /* Applied only inside a component */
  --zds-space-8: 0.5rem;
  --zds-space-12: 0.75rem;
  --zds-space-16: 1rem;
  --zds-space-20: 1.25rem;
  --zds-space-24: 1.5rem;
  --zds-space-28: 1.75rem;
  --zds-space-32: 2rem;
  --zds-space-36: 2.25rem;
  --zds-space-40: 2.5rem;
  --zds-space-44: 2.75rem;
  --zds-space-48: 3rem;
  --zds-page-header: 600 2.25rem/3rem "Degular Display", var(--zds-typography-heading-display, Helvetica), Helvetica, arial, sans-serif;
  --zds-section-header: 600 1.5rem/2.25rem "Degular Display", var(--zds-typography-heading-display, Helvetica), Helvetica, arial, sans-serif;
  --zds-section-header-multiline: 600 1.5rem/2rem "Degular Display", var(--zds-typography-heading-display, Helvetica), Helvetica, arial, sans-serif;
  --zds-paragraph-header-4: 700 1.125rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-paragraph-header-3: 600 1.125rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-paragraph-header-2: 500 1.125rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-paragraph-header-1: 400 1.125rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-title: 600 1rem/1.25rem "Degular Display", var(--zds-typography-heading-display, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-4: 700 0.875rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-4-multiline: 700 0.875rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-3: 600 0.875rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-3-multiline: 600 0.875rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-2: 500 0.875rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-2-multiline: 500 0.875rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-1: 400 0.875rem/1.5rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-body-1-multiline: 400 0.875rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-4: 700 0.8125rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-4-multiline: 700 0.8125rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-3: 600 0.8125rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-3-multiline: 600 0.8125rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-2: 500 0.8125rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-2-multiline: 500 0.8125rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-1: 400 0.8125rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-small-print-1-multiline: 400 0.8125rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-4: 700 0.75rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-4-multiline: 700 0.75rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-3: 600 0.75rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-3-multiline: 600 0.75rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-2: 500 0.75rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-2-multiline: 500 0.75rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-1: 400 0.75rem/1.25rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-minimal-print-1-multiline: 400 0.75rem/1rem Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  --zds-shadow-surface: 0 1px 18px 0 rgba(20,15,15,0.12), 0 6px 10px 0 rgba(20,15,15,0.14), 0 3px 5px -1px rgba(20,15,15,0.2);
  --zds-gradient-gradient-1: linear-gradient(140deg, #ffbf6e 0%, #FEA048 26%, #FF7F24 39%, #ff4f00 50%, #FF5789 61%, #F589DC 74%, #c1b7ff 100%);
  /* Peach - Orange - Lavender */
  --zds-ui-brand-weakest: #fdf5f2;
  --zds-ui-brand-weaker: #fed1c3;
  /* Old value: #FFB999 */
  --zds-ui-brand: #ff4f00;
  --zds-ui-brand-stronger: #cc3f00;
  --zds-ui-brand-strongest: #993109;
  --zds-ui-primary-weakest: #f7f6fd;
  --zds-ui-primary-weaker: #d5d7fc;
  --zds-ui-primary: #847dfe;
  --zds-ui-primary-stronger: #695be8;
  --zds-ui-primary-strongest: #503ebd;
  --zds-status-warning-weakest: #fff8d9;
  --zds-status-warning-weaker: #f4db6f;
  --zds-status-warning: #a88d10;
  --zds-status-warning-stronger: #86700b;
  --zds-status-warning-strongest: #645304;
  --zds-status-error-weakest: #fff2ef;
  --zds-status-error-weaker: #fdc9c6;
  --zds-status-error: #f65258;
  --zds-status-error-stronger: #d12d37;
  --zds-status-error-strongest: #a81227;
  --zds-status-success-weakest: #e7ffe7;
  --zds-status-success-weaker: #a7eaaa;
  --zds-status-success: #2ea343;
  --zds-status-success-stronger: #0a842a;
  --zds-status-success-strongest: #156123;
  --zds-radius-default: 0.3125rem;
  --zds-background-weaker: #fffdf9;
  --zds-background-default: #f9f7f3;
  --zds-background-stronger: #f5f3eb;
  --zds-background-strongest: #ece9df;
  --zds-background-selected: #f7f6fd;
  --zds-background-overlay: rgb(12.5% 8.24% 8.24% / 0.8);
  /* Background for elements displayed on top of the UI */
  --zds-stroke-weaker: #d7d3c9;
  --zds-stroke-default: #b5b2aa;
  --zds-stroke-stronger: #88827e;
  --zds-stroke-selected: #847dfe;
  --zds-reserved-beta: #d5d7fc;
  --zds-reserved-invite: #f6ffdb;
  --zds-reserved-free: #1a9f94;
  --zds-reserved-ai: linear-gradient(140deg, #ffbf6e 0%, #FEA048 26%, #FF7F24 39%, #ff4f00 50%, #FF5789 61%, #F589DC 74%, #c1b7ff 100%);
  --zds-text-weakest: #6f6765;
  --zds-text-weaker: #574e4c;
  --zds-text-default: #413735;
  --zds-text-stronger: #201515;
  --zds-text-link: #695be8;
  --zds-text-link-hover: #503ebd;
  --zds-text-inverted: #fffdf9;
  --zds-size-xsmall: 1.25rem;
  --zds-size-small: 1.5rem;
  --zds-size-medium: 2.25rem;
  --zds-size-large: 3rem;
  --zds-border-width-default: 0.0625rem;
  --zds-border-width-strong: 0.125rem;
  --zds-border-weak: 0.0625rem solid #d7d3c9;
  --zds-border-default: 0.0625rem solid #b5b2aa;
  --zds-border-focus: 0.125rem solid #695be8;
  --zds-inter: Inter, var(--zds-typography-base, Helvetica), Helvetica, arial, sans-serif;
  /* Body copy font */
  --zds-degular: "Degular Display", var(--zds-typography-heading-display, Helvetica), Helvetica, arial, sans-serif;
  /* Big heading */
  --zds-semibold-degular: 600;
  --zds-medium: 500;
  --zds-regular: 400;
  --zds-semi-bold-inter: 600;
  --zds-bold: 700;
  --zds-line-height-large: 3rem;
  --zds-line-height-medium: 2.25rem;
  --zds-line-height-medium-multiline: 2rem;
  --zds-line-height-small: 1.5rem;
  --zds-line-height-small-multiline: 1.25rem;
  --zds-line-height-xsmall: 1.25rem;
  --zds-line-height-xsmall-multiline: 1rem;
  --zds-letter-spacing-header: 0.0625rem;
  --zds-font-size-header-xlarge: 2.25rem;
  --zds-font-size-header-large: 1.5rem;
  --zds-font-size-header: 1.125rem;
  --zds-font-size-title: 1rem;
  /* Marketing purpose */
  --zds-font-size-default: 0.875rem;
  --zds-font-size-small-print: 0.8125rem;
  --zds-font-size-minimal: 0.75rem;
}