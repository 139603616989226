.catch-error {
  margin: 20px auto;
  max-width: 1080px;
}
.catch-error__details {
  margin: 0.5rem 1rem;
}
.catch-error__summary {
  cursor: pointer;
}
.catch-error__stack {
  background: rgba(255, 255, 255, 0.1);
  font-size: 10px;
}