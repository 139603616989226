.button-group {
  display: flex;
  flex-direction: column;
}
.button-group--block {
  width: 100%;
}
.button-group--reversed {
  flex-direction: column-reverse;
}
.button-group > * {
  flex: none;
  display: block;
  width: 100%;
}
.button-group:not(.button-group--reversed) > *:not(:last-child), .button-group.button-group--reversed > *:not(:first-child) {
  margin: 0 0 1em;
}
.button-group--horizontal {
  align-items: center;
  flex-direction: row;
}
.button-group--horizontal.button-group--reversed {
  flex-direction: row-reverse;
}
.button-group--horizontal > * {
  width: auto;
}
.button-group--horizontal:not(.button-group--reversed) > *:not(:last-child), .button-group--horizontal.button-group--reversed > *:not(:first-child) {
  margin: 0 1em 0 0;
}
.button-group--conjoined-two:not(.button-group--reversed) > *:not(:last-child), .button-group--conjoined-two.button-group--reversed > *:not(:first-child) {
  margin: 0 0;
}
.button-group--conjoined-two *:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.button-group--conjoined-two *:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.button-group--align-stretch {
  align-items: stretch;
}
.button-group--justify-end {
  justify-content: flex-end;
}
.button-group--justify-center {
  justify-content: center;
}
.button-group--justify-between {
  justify-content: space-between;
}
.button-group--flex-buttons > * {
  flex: 1;
}
.button-group--flex-grow-buttons > * {
  flex: 1 0 auto;
}